



























import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useStoryblokStyles } from '~/modules/storyblok/composables';

export default defineComponent({
  name: 'Column',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      alignVertically,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      marginTop,
      marginBottom,
      pcAlign,
      tabletAlign,
      align,
      pcWidth,
      tabletWidth,
      mobileWidth,
      mobileElementReverse,
    } = props.blok;

    const {
      paddingTopStyle,
      paddingBottomStyle,
      paddingLeftStyle,
      paddingRightStyle,
      marginTopStyle,
      marginBottomStyle,
      centerTextStyle,
      verticalAlignItemsStyle,
      widthStyle,
      mobileElementReverseStyle,
    } = useStoryblokStyles();

    const paddingTopClass = computed(() => paddingTopStyle(paddingTop as string));
    const paddingBottomClass = computed(() => paddingBottomStyle(paddingBottom as string));
    const paddingLeftClass = computed(() => paddingLeftStyle(paddingLeft as string));
    const paddingRightClass = computed(() => paddingRightStyle(paddingRight as string));
    const marginTopClass = computed(() => marginTopStyle(marginTop as string));
    const marginBottomClass = computed(() => marginBottomStyle(marginBottom as string));
    const centerTextClass = computed(() => centerTextStyle({ pcAlign, tabletAlign, align }));
    const verticalAlignItemsClass = computed(() => verticalAlignItemsStyle(alignVertically as string));
    const widthClass = computed(() => widthStyle({ pcWidth, tabletWidth, mobileWidth }));
    const mobileElementReverseClass = computed(() => mobileElementReverseStyle(mobileElementReverse as boolean));
    return {
      paddingTopClass,
      paddingBottomClass,
      paddingLeftClass,
      paddingRightClass,
      marginTopClass,
      marginBottomClass,
      centerTextClass,
      verticalAlignItemsClass,
      widthClass,
      mobileElementReverseClass,
    };
  },
});
