var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"reset-padding-on-mobile px-2",class:[
    _vm.paddingTopClass,
    _vm.paddingBottomClass,
    _vm.paddingLeftClass,
    _vm.paddingRightClass,
    _vm.marginTopClass,
    _vm.marginBottomClass,
    _vm.centerTextClass,
    _vm.verticalAlignItemsClass,
    _vm.widthClass,
    _vm.mobileElementReverseClass ]},[_c('div',{staticClass:"w-full"},_vm._l((_vm.blok.content),function(childrenBlok){return _c('StoryblokComponent',{key:childrenBlok._uid,attrs:{"blok":childrenBlok}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }