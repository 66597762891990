export interface UseStoryblokStylesInterface {
  justifyContentStyle(justifyContent: string): string;
  alignItemsStyle(justifyContent: string): string;
  paddingTopStyle(paddingTop: string): string;
  paddingBottomStyle(paddingBottom: string): string;
  paddingLeftStyle(paddingLeft: string): string;
  paddingRightStyle(paddingRight: string): string;
  marginTopStyle(marginTop: string): string;
  marginBottomStyle(marginBottom: string): string;
  verticalAlignItemsStyle(alignVertically: string): string;
  mobileElementReverseStyle(isMobileElementReverse: boolean): string;
  backgroundStyle(background: string): string;
  widthStyle({ pcWidth, tabletWidth, mobileWidth }: { pcWidth: string, tabletWidth: string, mobileWidth: string }): string;
  centerTextStyle({ pcAlign, tabletAlign, align }: { pcAlign: string, tabletAlign: string, align: string }): string;
}

/**
 * work in progess
 */
export function useStoryblokStyles(): UseStoryblokStylesInterface {
  const justifyContentStyle = (justifyContent:string) => {
    switch (justifyContent) {
      case 'justify-start': {
        return 'justify-start';
      }
      case 'justify-end': {
        return 'justify-end';
      }
      case 'justify-center': {
        return 'justify-center';
      }
      case 'justify-between': {
        return 'justify-between';
      }
      case 'justify-around': {
        return 'justify-around';
      }
      default: {
        return '';
      }
    }
  };
  const alignItemsStyle = (alignVertically:string) => {
    switch (alignVertically) {
      case 'items-start': {
        return 'items-start';
      }
      case 'items-center': {
        return 'items-center';
      }
      case 'items-end': {
        return 'items-end';
      }
      default: {
        return '';
      }
    }
  };
  const paddingTopStyle = (paddingTop:string) => {
    switch (paddingTop) {
      case 'zero': {
        return 'pt-0';
      }
      case 'xs': {
        return 'pt-2';
      }
      case 'sm': {
        return 'pt-4';
      }
      case 'md': {
        return 'pt-6';
      }
      case 'lg': {
        return 'pt-8';
      }
      case 'xl': {
        return 'pt-10';
      }
      default: {
        return '';
      }
    }
  };
  const paddingBottomStyle = (paddingBottom:string) => {
    switch (paddingBottom) {
      case 'zero': {
        return 'pb-0';
      }
      case 'xs': {
        return 'pb-2';
      }
      case 'sm': {
        return 'pb-4';
      }
      case 'md': {
        return 'pb-6';
      }
      case 'lg': {
        return 'pb-8';
      }
      case 'xl': {
        return 'pb-10';
      }
      default: {
        return '';
      }
    }
  };
  const paddingLeftStyle = (paddingLeft:string) => {
    switch (paddingLeft) {
      case 'zero': {
        return 'pl-0';
      }
      case 'xs': {
        return 'pl-2';
      }
      case 'sm': {
        return 'pl-4';
      }
      case 'md': {
        return 'pl-6';
      }
      case 'lg': {
        return 'pl-8';
      }
      case 'xl': {
        return 'pl-10';
      }
      default: {
        return '';
      }
    }
  };
  const paddingRightStyle = (paddingRight:string) => {
    switch (paddingRight) {
      case 'zero': {
        return 'pr-0';
      }
      case 'xs': {
        return 'pr-2';
      }
      case 'sm': {
        return 'pr-4';
      }
      case 'md': {
        return 'pr-6';
      }
      case 'lg': {
        return 'pr-8';
      }
      case 'xl': {
        return 'pr-10';
      }
      default: {
        return '';
      }
    }
  };
  const marginTopStyle = (marginTop:string) => {
    switch (marginTop) {
      case 'xs': {
        return 'mt-2';
      }
      case 'sm': {
        return 'mt-4';
      }
      case 'md': {
        return 'mt-6';
      }
      case 'lg': {
        return 'mt-8';
      }
      case 'xl': {
        return 'mt-10';
      }
      default: {
        return 'mt-0';
      }
    }
  };
  const marginBottomStyle = (marginBottom:string) => {
    switch (marginBottom) {
      case 'xs': {
        return 'mb-2';
      }
      case 'sm': {
        return 'mb-4';
      }
      case 'md': {
        return 'mb-6';
      }
      case 'lg': {
        return 'mb-8';
      }
      case 'xl': {
        return 'mb-10';
      }
      default: {
        return 'mb-0';
      }
    }
  };
  const verticalAlignItemsStyle = (alignVertically:string) => {
    switch (alignVertically) {
      case 'start': {
        return 'flex items-start';
      }
      case 'center': {
        return 'flex items-center';
      }
      case 'end': {
        return 'flex items-end';
      }
      default: {
        return '';
      }
    }
  };
  const mobileElementReverseStyle = (isMobileElementReverse:boolean) => (isMobileElementReverse ? 'mobile-reverse-order' : '');
  const backgroundStyle = (background) => (background && background.color ? `${background.color}` : '');
  const widthStyle = ({ pcWidth, tabletWidth, mobileWidth }) => {
    const widthStyleClasses = [];
    if (mobileWidth) {
      if (mobileWidth === '0') widthStyleClasses.push('hidden sm:block');
      widthStyleClasses.push(mobileWidth === '12' ? 'w-full' : `w-${mobileWidth}/12`);
    }
    if (tabletWidth) {
      if (tabletWidth === '0') widthStyleClasses.push('sm:hidden lg:block');
      widthStyleClasses.push(tabletWidth === '12' ? 'sm:w-full' : `sm:w-${tabletWidth}/12`);
    }
    if (pcWidth) {
      if (pcWidth === '0') widthStyleClasses.push('lg:hidden');
      widthStyleClasses.push(pcWidth === '12' ? 'lg:w-full' : `lg:w-${pcWidth}/12`);
    }
    return widthStyleClasses.join(' ');
  };
  const centerTextStyle = ({ pcAlign, tabletAlign, align }) => {
    const centerTextClasses = [];
    if (pcAlign) centerTextClasses.push(`lg:text-${pcAlign}`);
    if (tabletAlign) centerTextClasses.push(`sm:text-${tabletAlign}`);
    if (align) centerTextClasses.push(`text-${align}`);
    return centerTextClasses.join(' ');
  };

  return {
    justifyContentStyle,
    alignItemsStyle,
    paddingTopStyle,
    paddingBottomStyle,
    paddingLeftStyle,
    paddingRightStyle,
    marginTopStyle,
    marginBottomStyle,
    verticalAlignItemsStyle,
    mobileElementReverseStyle,
    backgroundStyle,
    widthStyle,
    centerTextStyle,
  };
}

export default useStoryblokStyles;
